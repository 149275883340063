import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";

String.prototype.b64encode = function() {
  return btoa(unescape(encodeURIComponent(this))).replaceAll("=", "");
};
String.prototype.b64decode = function() {
  return decodeURIComponent(escape(atob(this)));
};

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
