<template>
  <div
    style="min-height: 100%"
    class="layout column justify-center align-center"
  >
    <h1 class="flex x12 mt-10 text-center">BAS-IP Link push server</h1>
    <div class="pa-10 mt-10 white elevation-3">
      <div class="text-center title">Вход</div>
      <div>
        <v-text-field
          v-model="login"
          :disabled="loading"
          prepend-icon="mdi-account"
          label="Логин"
        />
        <v-text-field
          v-model="password"
          :disabled="loading"
          prepend-icon="mdi-lock"
          label="Пароль"
          type="password"
        />
      </div>
      <div class="mt-2">
        <v-btn
          dark
          :loading="loading"
          color="blue white-text"
          class="wide-button"
          @click="loginAttempt"
          >Войти</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      login: "",
      password: "",
      loading: false
    };
  },
  methods: {
    async loginAttempt() {
      this.loading = true;
      try {
        let result = await this.$store.dispatch("login", {
          login: this.login,
          password: this.password
        });
        if (!result) {
          this.$store.dispatch("addMessage", {
            text: "Неудачная попытка авторизации",
            type: "info"
          });
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.wide-button {
  display: block !important;
  width: 100%;
}
</style>
