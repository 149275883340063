<script>
import DeviceFilter from "@/components/DeviceFilter";
import { debounce } from "../utils/common";
export default {
  name: "DevicesPage",
  filter: {
    field: null,
    value: null
  },
  components: {
    DeviceFilter
  },
  data() {
    return {
      debouncedFetch: null,
      pagination: {
        page: 1,
        itemsPerPage: 15,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 100
      },
      sorting: {
        sort: "",
        order: "asc"
      },
      totalItems: 0,
      loading: false
    };
  },
  computed: {
    headers() {
      return [
        { text: "#", value: "id", sortable: true },

        { text: "Vendor id", value: "vendor_id", sortable: false },
        { text: "Платформа", value: "platform", sortable: false },
        { text: "Режим", value: "mode", sortable: false },
        { text: "Номер SIP", value: "sip_number", sortable: false },
        { text: "Link id", value: "link_id", sortable: false },
        { text: "Link user id", value: "link_user_id", sortable: false },

        { text: "Дата обновления", value: "updated_at", sortable: true }
      ];
    }
  },
  mounted() {
    this.debouncedFetch = debounce(this.fetchData, 500, false);
  },
  methods: {
    filterChanged(filterField, filterValue) {
      this.$options.filter.field = filterField;
      this.$options.filter.value = filterValue;
      this.debouncedFetch();
    },
    pickItem(item) {
      this.$store.commit("setDevice", { ...item });
      this.$router.push({ name: "DevicePage", params: { id: item.id } });
    },
    optionsChanged(options) {
      this.pagination.itemsPerPage = options.itemsPerPage;
      this.pagination.page = options.page;
      this.sorting.sort = options.sortBy.length ? options.sortBy[0] : "";
      this.sorting.order =
        options.sortDesc.length && options.sortDesc[0] ? "desc" : "asc";
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      try {
        await this.$store.dispatch("getDevices", {
          page: this.pagination.page,
          size: this.pagination.itemsPerPage,
          sortField: this.sorting.sort,
          sortOrder: this.sorting.order,
          filterField: this.$options.filter.field,
          filterValue: this.$options.filter.value
        });
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    }
  }
};
</script>

<style>
table tr td {
  cursor: pointer !important;
}
</style>

<template>
  <div class="">
    <div class="white elevation-1 pa-2">
      <device-filter @changed="filterChanged" />
    </div>

    <v-data-table
      :headers="headers"
      :items="$store.state.devices.items"
      :items-per-page="15"
      :server-items-length="$store.state.devices.totalItems"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15]
      }"
      class="white elevation-1 mt-2"
      @click:row="pickItem"
      @update:options="optionsChanged"
    >
    </v-data-table>
  </div>
</template>
