export function buildPaginatedQuery(
  filterValue,
  filterField,
  sortField,
  sortOrder,
  page,
  size
) {
  let result = `page=${page}&size=${size}`;

  let fv = (filterValue || "").trim();

  if (fv && filterField) {
    result += `&filter_field=${filterField}&filter_value=${filterValue}`;
  }

  if (sortField) {
    result += `&sort_field=${sortField}&sort_order=${sortOrder || "asc"}`;
  }

  return result;
}

export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}
