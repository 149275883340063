import Vue from "vue";
import Vuex from "vuex";
import Router from "@/router";
import moment from "moment";
import { buildPaginatedQuery } from "../utils/common";
import { md5 } from "../utils/md5";

const accessTokenKey = "accessToken";

Vue.use(Vuex);

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export default new Vuex.Store({
  getters: {
    isAuthenticated(state) {
      return !!state.authToken;
    }
  },
  state: {
    messages: [],
    authToken: sessionStorage.getItem(accessTokenKey),
    user: {
      id: sessionStorage.getItem("userId"),
      name: sessionStorage.getItem("userName")
    },
    devices: {
      items: [],
      totalItems: 0
    },
    device: null,
    deviceMessages: []
  },
  mutations: {
    clearAuth(state) {
      state.authToken = null;
      state.user.name = null;
      state.user.role = null;
      sessionStorage.removeItem(accessTokenKey);
      sessionStorage.removeItem("userName");
      sessionStorage.removeItem("userRole");
    },
    setSession(state, { token, userId, userName }) {
      state.authToken = token;
      state.user.name = name;
      state.user.id = userId;
      sessionStorage.setItem(accessTokenKey, token);
      sessionStorage.setItem("userId", userId);
      sessionStorage.setItem("userName", userName);
    },
    setDevices(state, { items, totalItems }) {
      state.devices.items = items
        ? items.map(i => {
            i.updated_at = moment(i.updated_at * 1000).format(
              "DD.MM.YYYY HH:mm"
            );
            return i;
          })
        : [];
      state.devices.totalItems = totalItems;
    },
    setDevice(state, device) {
      state.device = device;
    },
    setDeviceMessages(state, messages) {
      state.deviceMessages = messages.map(i => {
        i.created_at = moment(i.created_at * 1000).format("DD.MM.YYYY HH:mm");
        i.payload = null;
        return i;
      });
    },
    setMessagePayload(state, { messageId, payload }) {
      let msg = state.deviceMessages.find(i => i.id === messageId);
      if (msg) {
        msg.payload = payload;
      }
    },
    createMessage(state, message) {
      state.messages.push(message);
    },
    removeMessage(state, id) {
      state.messages = state.messages.filter(i => i.id !== id);
    }
  },
  actions: {
    async deleteDevice({ commit, state }, { deviceId }) {
      try {
        await fetch(`/api/v0/devices/${deviceId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          }
        });
        commit("setDevice", null);
        commit("setDeviceMessages", []);
      } catch (e) {
        console.log("getDevices", e);
      }
    },
    async addMessage({ commit }, { text, type = "info", timeout = 3000 }) {
      let id = getRandomInt(1, 999999);
      commit("createMessage", { id, text, type, timeout });
      if (timeout > 0) {
        setTimeout(() => {
          commit("removeMessage", id);
        }, timeout);
      }
    },
    async getDevices(
      { state, commit },
      { filterValue, filterField, sortField, sortOrder, page, size }
    ) {
      let query = buildPaginatedQuery(
        filterValue,
        filterField,
        sortField,
        sortOrder,
        page,
        size
      );

      try {
        let r = await fetch(`/api/v0/devices?${query}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          }
        });
        let result = await r.json();
        commit("setDevices", { items: result.items, totalItems: result.total });
      } catch (e) {
        console.log("getDevices", e);
      }
    },
    async getDeviceMessages({ state, commit }, { deviceId }) {
      try {
        let r = await fetch(`/api/v0/devices/${deviceId}/messages`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          }
        });
        commit("setDeviceMessages", (await r.json()).items || []);
      } catch (e) {
        console.log("getDeviceMessages", e);
      }
    },
    async getDevicePayload({ state, commit }, { messageId }) {
      try {
        let r = await fetch(`/api/v0/devices/messages/${messageId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          }
        });
        let result = await r.json();
        commit("setMessagePayload", { messageId, payload: result.payload });
      } catch (e) {
        console.log("getDevicePayload", e);
      }
    },
    async login({ commit }, { login, password }) {
      try {
        let response = await fetch(`/api/v0/login`, {
          method: "POST",
          body: JSON.stringify({
            login,
            password: md5(password)
          })
        });

        let result = await response.json();

        await commit("setSession", {
          token: result.access_token,
          userId: result.user.id,
          userName: result.user.name
        });
        Router.push({ name: "DevicesPage" });
        return true;
      } catch (e) {
        console.log("login error", e);
      }
      return false;
    },
    async logout({ commit }) {
      await commit("clearAuth");
      Router.push({ name: "LoginPage" });
    }
  },
  modules: {}
});
