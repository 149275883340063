<script>
export default {
  name: "DeviceFilter",
  data() {
    return {
      loading: false,
      filterField: "sip_number",
      filterValue: null
    };
  },
  computed: {
    filterFields() {
      return [
        { text: "Vendor ID", value: "vendor_id" },
        { text: "Link ID", value: "link_id" },
        { text: "Номер SIP", value: "sip_number" }
      ];
    }
  },
  methods: {
    search() {
      this.$emit("changed", this.filterField, this.filterValue);
    }
  }
};
</script>

<template>
  <div class=" layout">
    <div class="flex md3 pa-2">
      <v-select
        v-model="filterField"
        label="Фильтровать по"
        :items="filterFields"
        @change="search"
      />
    </div>
    <div class="flex md3 pa-2">
      <v-text-field
        v-model="filterValue"
        :loading="loading"
        prepend-icon="mdi-magnify"
        label="Введите информацию для поиска"
        clearable
        @keydown.enter="search"
        @input="search"
      />
    </div>
  </div>
</template>
